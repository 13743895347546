import axios from "axios";
import { EndPoints } from "../constants/Constants";

export const sendOtpViaSMS = async (recipientIdentifier: any) => {
  const formattedRecipientIdentifier = `${recipientIdentifier}`;

  try {
    const response = await axios.post(
      EndPoints.sendOTP + `sendOTP?phoneNumber=${formattedRecipientIdentifier}`
    );

    // Extract the verification ID from the response data
    const instanceId = response.data.instanceId;
    const checkCode = response.data.checkCode;

    return { instanceId, checkCode }; // Return the verification ID
  } catch (error) {
    // Handle the error as before
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      if (axiosError.response) {
        console.error("Error response from server:", axiosError.response.data);
        console.error("Status code:", axiosError.response.status);
      } else {
        console.error("Request failed:", axiosError.message);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    throw error; // Re-throw the error to propagate it further if needed
  }
};

export const verifyOtp = async (otpPasscode: any, checkCode: any) => {
  try {
    const response = await axios.post(
      EndPoints.sendOTP +
        `verifyOTP?otpPasscode=${otpPasscode}&checkCode=${checkCode}`
    );

    return response.data;
  } catch (error) {
    // Handle the error as before
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      if (axiosError.response) {
        console.error("Error response from server:", axiosError.response.data);
        console.error("Status code:", axiosError.response.status);
      } else {
        console.error("Request failed:", axiosError.message);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    throw error; // Re-throw the error to propagate it further if needed
  }
};
