import { CURRENCY, operationId } from "./../constants/Constants";
import axios from "axios";
import { EndPoints, LambdaNames } from "../constants/Constants";
import { invokeLambda } from "../helpers/utils";

// Function to create a booking
async function CreateBookingService({
  customerName,
  customerPhone,
  email,
  area,
  date,
  accompaniedCount,
  childCount,
  timeSlotID,
  eventGuests,
  depositStatus,
  depositValue,
  childDeposit,
  valetDeposit,
  conceptID,
}: any) {
  const CREATED_BY_ID = "2cb6d1b8-b7d8-447d-9267-54f77dbc643e";
  const PENDING_STATUS = "eba17df3-bc23-4c6e-ba49-06aa8aab769b";
  const ACCOUNT_ID = "5c57fcdf-f9f8-4351-84c0-14f437e3b13a";
  const CREATED_BY_NAME = "General";
  const bookingData = {
    operationId: operationId,
    currency: CURRENCY,
    mainGuest: "",
    customerName,
    customerPhone,
    email,
    area,
    date,
    accompaniedCount,
    childCount,
    timeSlotID: timeSlotID ? timeSlotID : "",
    timeSlots: timeSlotID ? [timeSlotID] : [],
    eventGuests: eventGuests ? eventGuests : [],
    tables: [],
    depositValue,
    depositStatus,
    childDeposit,
    valetDeposit,
    conceptID,
    accountID: ACCOUNT_ID,
    statusID: PENDING_STATUS,
    customerGroup: "normal",
    channel: "website", // mobile app
    comments: [],
    createdAt: new Date().toLocaleString(),
    createdByID: CREATED_BY_ID,
    createdByName: CREATED_BY_NAME,
  };
  try {
    const response = await invokeLambda(LambdaNames.createBooking, bookingData);
    return response;
  } catch (error) {
    console.error("Error creating booking:", error);
    throw error;
  }
}

export default CreateBookingService;
