// Separator.js
import { Divider, Grid } from "@mui/material";

const Separator = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={styles.formSeparator}
    >
      <Divider
        light={true}
        orientation="horizontal"
        sx={{
          bgcolor: "#5E273A",
          height: "2px",
          minWidth: "130px",
        }}
      />
    </Grid>
  );
};

const styles = {
  formSeparator: { marginTop: "20px", marginBottom: "20px" },
};

export default Separator;
