import { useDispatch } from "react-redux";
import { openSnackBar } from "../store/ducks/app";

const useApp = () => {
  const dispatch = useDispatch();

  function showError(error: any): string {
    if (error.message) {
      return error.message;
    } else if (error.errors && error.errors.length > 0) {
      return error.errors[0].message;
    } else {
      return error;
    }
  }

  return {
    showConfirm: (message: string) =>
      dispatch(openSnackBar(message, "success")),
    showError: (error: any) => {
      dispatch(openSnackBar(showError(error), "error"));
    },
    showWarning: (message: any) => {
      dispatch(openSnackBar(message, "warning"));
    },
    showInfo: (message: any) => {
      dispatch(openSnackBar(message, "info"));
    },
  };
};

export default useApp;
