import axios from "axios";
import { EndPoints, LambdaNames } from "../constants/Constants";
import { invokeLambda } from "../helpers/utils";

export const getVenues = async () => {
  try {
    const response = await invokeLambda(LambdaNames.Venues, {});

    return response;
  } catch (error) {
    // Handle errors more informatively, log, or re-throw if appropriate
    console.error("Error retrieving parent concepts:", error);
  }
};

export const getTimeSlots = async (conceptid: any) => {
  try {
    // const lambda = new AWS.Lambda();
    // const params = {
    //   FunctionName: 'hospitalityOfflineListSlots-bakyprod',
    //   Payload: JSON.stringify({ id : conceptid }), // Pass conceptid as the payload
    // };

    // const response = await lambda.invoke(params).promise();

    // // Handle potential errors in the response
    // if (response.StatusCode !== 200) {
    //   throw new Error(`Lambda invocation failed with status: ${response.StatusCode}`);
    // }

    // const data = JSON.parse(JSON.parse(response.Payload).body);

    // return data;
    const response = await axios.get(
      EndPoints.TimeSlots + `?id=${conceptid ? conceptid : ""}`
    );
    // const response = await invokeLambda(LambdaNames.TimeSlots,queryStringParameters);

    return response.data;
  } catch (error) {
    console.error("Error retrieving TimeSlots:", error);
    throw new Error("Failed to retrieve TimeSlots"); // Or return a custom error object
  }
};
