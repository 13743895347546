import "./Slider.css";
import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@mui/styles";

interface SliderProps {
  images: any;
  isMobile: any;
}

const useStyles = makeStyles({
  carousel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1rem",
  },
  image: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    borderRadius: "10px",
  },
});
const Slider: React.FC<SliderProps> = ({ images, isMobile }: any) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Carousel
      className={classes.carousel}
      sx={{ width: isMobile ? "90%" : "70%" }}
      index={activeIndex}
      animation="slide"
      onChange={(index: any) => setActiveIndex(index)}
      indicators={false} // This hides the built-in dots (indicators)
      navButtonsAlwaysVisible={false} // Hide next and previous icons
    >
      {images.map((image: any, index: any) => (
        <img
          key={index}
          alt={`Slide ${index + 1}`}
          src={image}
          className={classes.image}
        />
      ))}
    </Carousel>
  );
};

export default Slider;
