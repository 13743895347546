import  { useState, useEffect } from 'react';
import { GetNotificationsService } from '../services/GetEventsService';

export const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setIsLoading] = useState(true);
  const [notificationsError, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setIsLoading(true);
        const data = await GetNotificationsService();
        setNotifications(data);
      } catch (err:any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  return { notifications, notificationsLoading, notificationsError };
};

export default useNotifications