// SplashScreen.js
import React, { useEffect, useState } from "react";
import { IMAGE } from "../../constants/Constants";
import "./SplashScreen.css";

interface Props {
  venue: any;
}

const SplashScreen: React.FC<Props> = ({ venue }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`splash-screen ${visible ? "visible" : "hidden"}`}>
      <img src={require(`../../assets/${IMAGE}`)} alt="splash" />
    </div>
  );
};

export default SplashScreen;
