// BookingOTP.js
import {
  Button,
  Container,
  createTheme,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import useApp from "../../hooks/useApp";
import { sendOtpViaSMS, verifyOtp } from "../../services/OtpServices";
import "./BookingOTP.css";
import ButtonLoader from "../UI/ButtonLoader";
import { Screens } from "../../constants/enums";
import lightThemeOptions from "../../theme/lightThemeOptions";

interface Props {
  phoneCode: string;
  booking: any;
  guests: any;
  eventDeposit: any;
  conceptID: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const theme = createTheme(lightThemeOptions);

const BookingOTP: React.FC<Props> = ({
  phoneCode,
  booking,
  guests,
  eventDeposit,
  conceptID,
  setPage,
}) => {
  const { showError } = useApp();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [phone, setPhone] = useState("");
  useEffect(() => {
    if (booking) {
      setPhone(booking.mobile);
      handleSendCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const handleSendCode = async () => {
    if (!sendCodeLoading) {
      const phoneNumber = booking ? booking.mobile : phone;
      const lastRequest: any = await localStorage.getItem("otpSend");
      const timeNow = new Date().getTime() + "";

      try {
        if (parseInt(timeNow) - parseInt(lastRequest) < 60 * 1000) {
          showError("Kindly wait 60 seconds before sending another request");
          return;
        }

        setSendCodeLoading(true);

        await localStorage.setItem("otpSend", new Date().getTime() + "");
        const response = await sendOtpViaSMS(
          phoneCode +
            (phoneCode === "+20" && phoneNumber.slice(0, 1) === "0"
              ? phoneNumber.slice(1, phoneNumber.length)
              : phoneNumber)
        );

        setCheckCode(response.checkCode);
      } catch (error) {
        console.error("Error sending OTP:", error);
        showError("Failed to send OTP, Please try again.");
      }
      setSendCodeLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);
      const verify = await verifyOtp(otp, checkCode);
      const otpStatus = verify.data.otpPasscodeStatus;
      if (otpStatus === 0) {
        showError("OTP Expired, please try again with the new OTP.");
      } else if (otpStatus === 2) {
        showError("OTP wrong, please try again.");
      } else if (otpStatus === 1) {
        // Navigate to preview page and confirm booking
        setPage(Screens.BOOKING_CONFIRMATION);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error.response.data.replyCode === 2001) {
        showError("You have exceeded maximum number of trials.");
      } else {
        showError(error);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          width: "90%",
          marginTop: "50px",
          zIndex: "1",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Typography
            sx={{
              fontSize: 16,
              color: "#ccc",
              textAlign: "center",
              marginBottom: "1rem",
              fontWeight: 400,
            }}
            variant="h6"
            className="typography"
          >
            Kindly enter the verification code (OTP) <br></br> sent to{" "}
            {phoneCode +
              (phoneCode === "+20" && phone.slice(0, 1) === "0"
                ? phone.slice(1, phone.length)
                : phone)}
          </Typography>
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <OtpInput
              shouldAutoFocus={true}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              containerStyle={{
                alignItems: "center",
                justifyContent: "center",
              }}
              renderSeparator={<span>-</span>}
              renderInput={(props: any) => (
                <input {...props} className="input" />
              )}
            />
          </Grid>

          <Typography
            sx={{
              fontSize: 14,
              color: "#aaa",
              textAlign: "center",
              marginTop: "1rem",
              fontWeight: 400,
            }}
            variant="h6"
          >
            Didn't receive a code ?{"  "}
            <span
              style={{ color: theme.palette.primary.dark, cursor: "pointer" }}
              onClick={handleSendCode}
            >
              resend.
            </span>
          </Typography>

          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: { xs: 2, sm: 2 },
            }}
          >
            <Button
              type="submit"
              variant="contained"
              size="small"
              disableElevation
              endIcon={loading && <ButtonLoader />}
              disabled={loading}
              style={{ ...styles.submitButton }}
              onClick={() => verifyOTP()}
              sx={{ mt: 2, minWidth: "20%", borderRadius: 10 }}
            >
              Verify
            </Button>

            <Button
              type="submit"
              variant="contained"
              size="small"
              disableElevation
              disabled={loading}
              style={styles.submitButton}
              onClick={() => setPage(Screens.BOOKING_FORM)}
              sx={{ mt: 2, minWidth: "20%", borderRadius: 10 }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const styles = {
  submitButton: {
    borderRadius: 10,
  },
};

export default BookingOTP;
