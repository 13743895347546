import "./App.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReservationPage from "./pages/reservationPage/ReservationPage";
import AWS from "aws-sdk";
import { COGNITO_IDENTITYPOOLID } from "./constants/Constants";
import { setIsDarkMode } from "./store/ducks/app";
import MainSnackbar from "./components/UI/MainSnackbar";
import { useSelector } from "react-redux";
import ErrorBoundary from "./components/UI/ErrorBoundary";

function App() {
  const { i18n } = useTranslation();
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);

  AWS.config.region = "us-east-2";
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: COGNITO_IDENTITYPOOLID,
  });

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("language");

    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    // Check if the browser supports matchMedia
    if (!window.matchMedia) return;

    // Check if the user's device prefers dark mode
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(darkModeQuery.matches);

    // Listen for changes in dark mode preference
    const handleChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
    };
    darkModeQuery.addEventListener("change", handleChange);

    // Clean up the event listener
    return () => {
      darkModeQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Routes>
          <Route path="/:concept" element={<ReservationPage />} />
        </Routes>
        {snackBarOpen && <MainSnackbar />}
      </ErrorBoundary>
    </>
  );
}

export default App;
