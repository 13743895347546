import React from "react";
import { Box } from "@mui/material";
import Slider from "../../components/Slider/Slider";
import useNotifications from "../../hooks/useNotifications";
import useIsMobile from "../../hooks/useIsMobile";

interface Props {}

const Banner: React.FC<Props> = ({}) => {
  const { web } = useIsMobile();
  const { notifications } = useNotifications();

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "1rem",
        }}
      >
        {notifications && notifications.filter((item: any) => item.event) && (
          <Slider
            isMobile={!web}
            images={notifications
              .filter((item: any) => item.event)
              .map((item: any) => item.image)}
          />
        )}
      </Box>
    </>
  );
};

export default Banner;
