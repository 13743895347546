

import  { useState, useEffect } from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 786);
    const [isTablet, setIsTablet] = useState(
      window.innerWidth < 1024 && window.innerWidth > 785
    );
    const [width,setWidth] = useState(window.innerWidth);
        let web = !isMobile && !isTablet
    useEffect(() => {
        function handleResize() {
          setIsMobile(window.innerWidth < 786);
          setIsTablet(window.innerWidth < 1024 && window.innerWidth > 785);
        }
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

  return { isMobile, isTablet,web, width };
};

export default useIsMobile