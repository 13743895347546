import AWS from "aws-sdk";

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function enableVenueDeposit(
  venue: any,
  date: string
): Promise<boolean> {
  if (!venue) return false;

  let currentDay = await getDayName(date);
  let days = venue.depositEnabledDays || [];
  let enableDeposit =
    venue.eventDeposit && days.length > 0 && days.includes(currentDay)
      ? true
      : false;

  return enableDeposit;
}

export function getDayName(inputDate: string): string {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(inputDate);
  const dayIndex = date.getDay();
  return days[dayIndex];
}

// dd-mm-yyyy
export function formatDateDDMMYYY(inputDate: string): string {
  let date = inputDate !== "" ? new Date(inputDate) : new Date();

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
}

// Format date object to YYYYMMDDHHMMSS
export function formatDateToYYYYMMDDHHMMSS(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function getTimeSlotName(timeSlots: any[], id: string): string {
  let name = "";
  for (const timeSlot of timeSlots) {
    if (timeSlot.id === id) {
      name = timeSlot.name.split(" - ")[0];
      break;
    }
  }
  return name;
}

export async function invokeLambda(functionName: any, requestBody: any) {
  let lambda = new AWS.Lambda();

  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    let response: any = await lambda
      .invoke({
        //egy
        FunctionName: `${functionName}-taghmisa`,
        //ksa
        // FunctionName: `${functionName}-tagmisaksa`,
        Payload: JSON.stringify(options),
      })
      .promise();

    if (response.StatusCode !== 200) {
      throw new Error(
        `Lambda invocation returned status code: ${response.StatusCode}`
      );
    }

    if (!response.Payload) {
      throw new Error("No payload received from lambda function");
    }
    let payload;

    payload = JSON.parse(response.Payload);
    if (payload.statusCode !== 200) {
      throw new Error(
        `Lambda function returned error with status code: ${payload.statusCode}`
      );
    }
    if (!payload.body) {
      throw new Error("No body in the payload");
    }

    let body = JSON.parse(payload.body);

    return body;
  } catch (error) {
    console.error("Error invoking lambda function:", error);
    // Depending on your use case, you might want to rethrow the error, return a default value, or handle it in another way.
    throw error; // Rethrow the error if you want the caller to handle it.
  }
}
