// import { LambdaNames } from "../constants/Constants";
// import { invokeLambda } from "../helpers/utils";

import { PAYMENT_LINK } from "../constants/Constants";

function createHashLink(booking: any) {
  return `${PAYMENT_LINK}${booking.id}`;
}

// Function to create a booking
async function sendEmail(templateName: string, email: string, booking: any) {
  // Booking Data
  let bookingLink = createHashLink(booking);
  let customerEmail = email;
  let customerName = booking.customerName;
  let numOfGuests = booking.accompaniedCount;
  let reservationDate = booking.date;
  let reservationTime = "";

  // Prepare Request
  const SOURCE_EMAIL = "info@anyware.software";
  const QUERY_PARAMS = `?customer_name=${customerName}&customer_email=${customerEmail}&reservation_date=${reservationDate}&reservation_time=${reservationTime}&number_of_guests=${numOfGuests}&source_email=${SOURCE_EMAIL}&template_name=${templateName}&booking_link=${bookingLink}`;
  const MAIL_URL = `https://talnzuxrs26b7pgcqri4awulwa0dddtq.lambda-url.us-east-2.on.aws/${QUERY_PARAMS}`;

  let mailSent;
  try {
    await window
      .fetch(MAIL_URL)
      .then((response) => {
        response.status === 200 ? (mailSent = true) : (mailSent = false);
      })
      .catch((err) => {
        mailSent = false;
      });

    if (!mailSent) {
      console.log(`Failed to send email for this customer.`, { email });
    }
  } catch (err) {
    console.log(err);
    console.log(`Failed to send email for this customer.`);
  }

  //   try {
  //     let body = {};
  //     const response = await invokeLambda(LambdaNames.createBooking, body);
  //     return response;
  //   } catch (error) {
  //     console.error("Error creating booking:", error);
  //     throw error;
  //   }
}

export default sendEmail;
