import { useEffect, useState } from "react";
import { getTimeSlots, getVenues } from "../services/VenuesService";
import { useParams } from "react-router-dom";

const useVenue = () => {
  const [AllVenues, setAllVenues] = useState([]);
  const [venue, setVenue] = useState<any>();
  const [venueLoading, setLoading] = useState(false);
  const [venueError, setError] = useState(null);
  const params = useParams();
  const concept = (params.concept || "default")
    .replace(/-/g, " ")
    .toLowerCase();

  const fetchTimeSlotsFromAPIWithId = async (id: any) => {
    try {
      const data = await getTimeSlots(id);
      return data;
    } catch (error: any) {
      setError(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      await fetchConceptsFromAPI();
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchConceptsFromAPI = async () => {
    try {
      setLoading(true);
      const data = await getVenues();

      AddTimeSlots(data);
      setAllVenues(data);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const AddTimeSlots = async (AllVenues: any) => {
    try {
      setLoading(true);
      const venue = AllVenues.find(
        (item: any) => item.name.toLowerCase() === concept
      );

      if (venue === undefined) {
        console.log("Invalid concept name");
        return;
      }

      const timeSlots = await fetchTimeSlotsFromAPIWithId(venue.id);

      // Sort time slots by precedence
      const listSorted = timeSlots.sort((a: any, b: any) => {
        return a.precedence - b.precedence;
      });

      // Add the timeSlots result to the venue object
      const updatedVenue = {
        ...venue,
        timeSlots: listSorted,
      };

      setVenue(updatedVenue);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    venue,
    AllVenues,
    venueLoading,
    venueError,
  };
};

export default useVenue;
