import { invokeLambda } from '../helpers/utils';
import { LambdaNames } from '../constants/Constants';

export const GetNotificationsService = async () => {
  try {
    const response = await invokeLambda(LambdaNames.getNotifications,{operationId:1})

    return response; // Return the parsed response data
  } catch (error) {
    console.error('Error retrieving notifications:', error);
    // throw new Error('Error retrieving notifications'); // Or return a custom error object
  }
};
